import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Spinnere",
  "template": "default"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Bruk av spinnere`}</h2>
    <p>{`Det er mye som skjer i bakgrunnen i et system når innholdet lastes inn. Med spinnere kan vi kommunisere tydelig til
brukeren at innholdet er i ferd med å vises snart. Det er best practice å benytte seg av spinnere dersom ventetiden varer lengre enn tre sekunder. Dersom siden bruker mer enn 10 sekunder på å laste inn innholdet, vurder å bruke en notifikasjon med forklarende tekst.`}</p>
    <h2>{`Spinner`}</h2>
    <p>{`Spinneren finnes i liten og stor versjon.`}</p>
    <DSExample name="spinner_Spinner" mdxType="DSExample" />
    <UUTestReport reportID="Spinner_Spinner" mdxType="UUTestReport" />
    <DSExample name="spinner_Spinner_large" mdxType="DSExample" />
    <h2>{`Spinner i knapper`}</h2>
    <p>{`De viktigste knappene våre har også en innebygget spinner, som vises ved å legge inn en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isLoading`}</code>{`-prop på knappen.`}</p>
    <DSExample name="buttons_ariaLoadingMessage" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      